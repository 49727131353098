import moment from "moment"
import momenttm from 'moment-timezone';


const isMobileDevice =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
const isMobileDevice1 = window.matchMedia("(max-width: 767px)").matches;

export const isMobile = isMobileDevice || isMobileDevice1 ? true : false;

export const formatDate = (dateString: string): string => {


  // const options: Intl.DateTimeFormatOptions = {
  //   weekday: "short",
  //   day: "2-digit",
  //   month: "short",
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   hour12: false,
  //   timeZone: 'UTC',
  // };

  // const formattedDate: string = new Date(dateString).toLocaleString(
  //   "en-US",
  //   options
  // ).replace(/,/g, "").replace(/ /, ", ");
  const formattedDate = moment(dateString).format('YYYY-MM-DD hh:mm:ss')
  return formattedDate;
};

export function getTabName(tabIndex: number) {
  switch (tabIndex) {
    case 0:
      return "Exchange";
    case 1:
      return "FancyBet";
    case 2:
      return "Sportsbook";
    case 3:
      return "BookMaker";
    case 4:
      return "casino";
    default:
      return "";
  }
}

export const formatTime = (dateTimeString: string) => {
  const date = new Date(dateTimeString);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

 

  // Check if the date is today
  if (date.toDateString() === today.toDateString()) {
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  // Check if the date is tomorrow
  else if (date.toDateString() === tomorrow.toDateString()) {
    return `Tomorrow ${hours}:${minutes}`;
  }
  // For dates beyond tomorrow
  else {
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

};

export const stakes = [50, 100, 200, 500, 3000, 5000];

export const betSlipAmounts = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
  "00",
];

export const filters = [
  "All",
  "Popular",
  "Match",
  "Innings",
  "Over",
  "Batsman",
  "Partnership",
  "Group",
  "Range",
  // "Head-to-Head",
  // "Player"
];
export const filters1 = ["All", "Popular", "Goals", "Over"];
export const premiumfilters = [
  "All",
  "Popular",
  "Match",
  "Innings",
  "Over",
  "More",
];


// time difference
export function getTimeDifferenceInMinutes(startTime:string) {
  const currentTime = new Date();
  const startDate = new Date(startTime);
  // @ts-ignore
  const differenceInMs = startDate - currentTime;
  const differenceInMinutes = Math.floor(differenceInMs / 1000 / 60);
  return differenceInMinutes;
}



export function isEmpty(obj:object) {
  return Object.keys(obj).length === 0;
}

// function to calculate the time until the next race
export const calculateNextRaceTime = (startTime:string) => {
  const now = new Date();
  const eventTime = new Date(startTime);
  const diff = (eventTime.getTime() - now.getTime()) / 60000; 

  if (diff <= 0) {
    return "The event has already started or passed.";
  }

  const hours = Math.floor(diff / 60);
  const minutes = Math.ceil(diff % 60);

  if (hours > 0) {
    return `${hours} hrs ${minutes} mins`;
  } else {
    return `${minutes} mins`;
  }
};


